import React, { useEffect, useState } from 'react'
import {BsLinkedin , BsInstagram , BsGithub} from 'react-icons/bs'
import axios from 'axios';


const Header_social = () => {
  const [user, setUser] = useState({ description: '' });

  const socialMedia = {
    linkedin :"https://www.linkedin.com/in/mohammed-reda-elakhal-3967ab282/",
    instagram : "https://www.instagram.com/mohammed_reda_69/?next=%2F",
    github : "https://github.com/mohammed-reda-elakhal",
  }
  useEffect(() => {
    axios.get('http://localhost:5000/api/user/4')
      .then(res => setUser(res.data))
      .catch(err => console.log(err.message));
  }, []);
  return (
    <div className='Header_social'>
        <a href={socialMedia.instagram} target='_blank'>
            <BsInstagram/>
        </a>
        <a href={socialMedia.github}  target='_blank'>
            <BsGithub/>
        </a>
        <a href={socialMedia.linkedin}  target='_blank'>
            <BsLinkedin/>
        </a>
    </div>
  )
}

export default Header_social