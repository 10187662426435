import React, { useEffect, useState } from 'react';
import './about.css';
import Me from './../../assets/me-about.png';
import { FaAward, FaUserAlt, FaFolderOpen } from 'react-icons/fa';
import axios from 'axios';

const About = () => {
  const [user, setUser] = useState({ description: '' });

  const userDescription = "Welcome to my portfolio! I'm Mohammed Reda, a dedicated and driven individual with a passion for the world of information technology. \n" + 
  " Armed with a comprehensive two-year diploma in Computer Engineering with a specialization in database administration, I am currently a student at EST Fes. \n" + 
  " My journey in the realm of technology has equipped me with a deep understanding of database systems and their intricate management. \n"+ 
  " Through my academic pursuits and practical experiences, I have honed the skills necessary to navigate the complexities of the digital landscape. Join me as I showcase my proficiency in database administration and my commitment to pushing the boundaries of what's possible in the world of IT. \n";

  const paragraphs = userDescription.split('\n');

  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_img">
            <img src={Me} alt="" />
          </div>
        </div>
        <div className="about_content">
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <a href="#contact" className='btn btn-primary'>Let's talk</a>
        </div>
      </div>
    </section>
  );
};

export default About;
