import React, { useEffect, useState } from 'react'
import './portfolio.css';
import portfolioImg from '../../assets/portfolio-img.png'
import siteClassic from '../../assets/site-classic.png'
import deliveriWebsite from '../../assets/frontend-delivery website.png'
import StockApp from '../../assets/stock-app.png'
import downtimeApp from '../../assets/downtime-application.png'
import BlogApp from '../../assets/BlogApp.png'
import DataVis from '../../assets/data-visual.png'
import pricingImg from '../../assets/pricing.png'


import axios from 'axios'

import { BsGithub } from 'react-icons/bs';

const data = [
  {
    id:1 ,
    image : portfolioImg ,
    title : "WEB APP",
    name : "Portfolio Responsive",
    describe : "Build Portfolio responsive using React js and Node js",
    BsGithub : 'https://delivrio.online/'
  },{
    id:2 ,
    image : siteClassic ,
    title : "WEB APP",
    name : "Classic Vitrine APP",
    describe : "Frontend Web Site vitrine with Classic Design",
    BsGithub : 'https://moristile.com/'
  },{
    id:3 ,
    image : deliveriWebsite ,
    title : "WEB APP",
    name : "Delivery APP",
    describe : "Frontend Delivery Web Site Modern and responsive",
    BsGithub : 'https://yamoexpress.ma/fr'
  },{
    id:4 ,
    image : downtimeApp ,
    title : "DESKTOP APP",
    name : "Downtime monitor",
    describe : "APP for manage downtime of technicien maintenance and KPI ( MTBF & MTTR )",
    BsGithub : 'https://github.com/mohammed-reda-elakhal/DOWNTIME-MONITORE'
  },{
    id:5 ,
    image : StockApp ,
    title : "DESKTOP APP",
    name : "Simple Inventory Management",
    describe : "APP for manage stock and Statistic for command",
    BsGithub : 'https://github.com/mohammed-reda-elakhal/Stock-APP'
  },
  {
    id:6 ,
    image : BlogApp ,
    title : "WEB APP",
    name : "Crud APP",
    describe : "Simple Crud application using ASP.NET Core and SQL Server",
    BsGithub : 'https://github.com/mohammed-reda-elakhal/Blog-App-Simple'
  },
  {
    id:7 ,
    image : DataVis ,
    title : "WEB APP",
    name : "Crud APP",
    describe : "APP for visualisation Data about Casualty in New York",
    BsGithub : 'https://github.com/mohammed-reda-elakhal/PFE-DATA-Visualisation'
  },
  {
    id:8 ,
    image : pricingImg ,
    title : "WEB APP",
    name : "Pricing Service APP",
    describe : "Landing APP for pricing service using next js and typeScript",
    BsGithub : 'https://black-dev-pack.vercel.app/'
  },
 
]
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="portfolio portfolio_container">
        {
          data.map((projet)=>{
            return(
              <article key={projet.id} className='portfolio_item'>
                <h4 className='title_projet'>{projet.title}</h4>
                <div className="portfolio_img">
                  <img src={projet.image} alt="" />
                </div>
                <h3>{projet.name}</h3>
                <p className='des_projet'>{projet.describe}</p>
                <a href={projet.github} className='btn' target='_blanck'  rel="noreferrer" >Github</a>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio