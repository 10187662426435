import React, { useEffect, useState } from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';
import axios from 'axios';

const Experience = () => {
  const [categories, setCategories] = useState([]);
  const [experiencesByCategory, setExperiencesByCategory] = useState({});

  const web = [
    {
      key : 1 ,
      name : "HTML"
    },
    {
      key : 2 ,
      name : "CSS"
    },
    {
      key : 3 ,
      name : "TAILWIND CSS"
    },
    {
      key : 4 ,
      name : "BOOTSTRAP"
    },
    {
      key : 5 ,
      name : "REACT JS"
    },{
      key : 6 ,
      name : "NODE JS"
    },
    {
      key : 7 ,
      name : "EXPRESS JS"
    },
    {
      key : 8 ,
      name : "ASP.NET CORE"
    },
  ]

  const language = [
    {
      key : 1 ,
      name : "JAVASCRIPT"
    },
    {
      key : 2 ,
      name : "C#"
    },
  ]

  const database = [
    {
      key : 1 ,
      name : "MY SQL"
    },
    {
      key : 2 ,
      name : "SQL SERVER"
    },{
      key : 3 ,
      name : "Entity Framwork"
    },{
      key : 4 ,
      name : "Sequelize"
    },
  ]

  const tools = [
    {
      key : 1 ,
      name : "VS Code"
    },
    {
      key : 2 ,
      name : "Visual Studio 2022"
    },{
      key : 3 ,
      name : "Postman"
    },{
      key : 4 ,
      name : "Canva"
    },
    {
      key : 5 ,
      name : "Figma"
    },

  ]

  return (
    <section id='experience'>
      <h5>What Skill I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
          <div className="">
            <h3>Web Technology</h3>
              <div className="experience_content">
                {
                  web.map((Element)=>(
                    <article className="experience_detail" key={Element.key}>
                    <BsPatchCheckFill className='experience_icon' />
                    <div>
                      <h4>{Element.name}</h4>
                      <small className="text-light">
                        Experied
                      </small>
                    </div>
                  </article>
                  ))
                }
                  
              </div>
          </div>

          <div className="">
            <h3>Language Programming</h3>
              <div className="experience_content">
                {
                  language.map((Element)=>(
                    <article className="experience_detail" key={Element.key}>
                    <BsPatchCheckFill className='experience_icon' />
                    <div>
                      <h4>{Element.name}</h4>
                      <small className="text-light">
                        Experied
                      </small>
                    </div>
                  </article>
                  ))
                }
                  
              </div>
          </div>


          <div className="">
            <h3>DataBase</h3>
              <div className="experience_content">
                {
                  database.map((Element)=>(
                    <article className="experience_detail" key={Element.key}>
                    <BsPatchCheckFill className='experience_icon' />
                    <div>
                      <h4>{Element.name}</h4>
                      <small className="text-light">
                        Experied
                      </small>
                    </div>
                  </article>
                  ))
                }
                  
              </div>
          </div>

          <div className="">
            <h3>Tools</h3>
              <div className="experience_content">
                {
                  tools.map((Element)=>(
                    <article className="experience_detail" key={Element.key}>
                    <BsPatchCheckFill className='experience_icon' />
                    <div>
                      <h4>{Element.name}</h4>
                      <small className="text-light">
                        Experied
                      </small>
                    </div>
                  </article>
                  ))
                }
                  
              </div>
          </div>
      </div>
    </section>
  );
};

export default Experience;
