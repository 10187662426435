import React, { useEffect, useState } from 'react';
import './services.css';
import { BiCheck } from 'react-icons/bi';
import axios from 'axios';

const Services = () => {

  const service = [
    {
      key : 1 ,
      name : "Web Development and Design",
      description : "Creating user-friendly websites using foundational technologies like HTML, CSS, and beginner-friendly frameworks such as Bootstrap and Tailwind CSS. Through hands-on practice, I've learned to build responsive web interfaces, focusing on simplicity and user interaction to create engaging web experiences."
    },
    {
      key : 2 ,
      name : " Full-Stack Application Development",
      description : "Exploring both front-end (React.js) and back-end (Node.js, Express.js, ASP.NET Core) technologies to develop basic full-stack applications. By working on practical projects, I've gained exposure to crafting interactive interfaces and implementing simple server-side functionalities, gradually expanding my skills in full-stack development."
    },
    {
      key : 3 ,
      name : "JavaScript and C# Programming",
      description : "Embarking on the journey of JavaScript and C# programming, I've gained foundational knowledge in these languages. Through guided projects, I've started leveraging JavaScript's capabilities with React.js and Node.js, along with the versatility of C# within the ASP.NET Core framework to develop basic applications."
    },
    {
      key : 4 ,
      name : "Database Management and Integration",
      description : "Exploring fundamental database management with SQL Server, MySQL, Entity Framework, and Sequelize. By practicing database integration in small projects, I've learned basic data storage and retrieval techniques, aiming for optimal performance and security."
    },
    {
      key : 5 ,
      name : "Development of Windows Desktop Applications",
      description : "Embarking on the creation of Windows desktop applications using C# WinForms, I've delved into the world of software development, focusing on building applications tailored for the Windows operating system."
    }, {
      key : 6 ,
      name : "UI/UX Design Experimentation",
      description : "Diving into UI/UX design principles using tools like Figma and Canva. Through experimentation and small-scale design projects, I've begun crafting user interfaces with a focus on usability, aesthetics, and basic prototyping, aiming to create visually pleasing designs."
    },

  ]

  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services_container">
        {service.map((serviceItem) => (
          <article className="service" key={serviceItem.id}>
            <div className="service_head">
              <h3>{serviceItem.name}</h3>
            </div>
            <ul className="service_list">
              {serviceItem.description && serviceItem.description.split('\n').map((paragraph, index) => (
                <li key={index}>
                  <BiCheck className="service_list-icon" />
                  <p>{paragraph}</p>
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Services;
